import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faHeart, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Help: React.FC<{ borderColor?: string }> = ({ borderColor }) => {
  const className = `flex border rounded-lg h-12 w-12 ${borderColor || "border-black"} place-items-center place-content-center mx-auto`;
  const spaceClassName = `flex border rounded-lg px-3 py-1 ${borderColor || "border-black"} place-items-center place-content-center`
  return <div className="grid grid-cols-[auto,auto] gap-3 font-bold items-center text-lg">
    <p className={className}>&#91;</p>
    <p>Decrease playback speed by 1%</p>
    <p className={className}>&#93;</p>
    <p>Increase playback speed by 1%</p>
    <p className={className}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </p>
    <p>Rewind 3 seconds</p>
    <p className={className}>
      <FontAwesomeIcon icon={faArrowRight} />
    </p>
    <p>Seek 3 seconds forward</p>
    <p className={className}>
      <FontAwesomeIcon icon={faArrowUp} />
    </p>
    <p>Volume up</p>
    <p className={className}>
      <FontAwesomeIcon icon={faArrowDown} />
    </p>
    <p>Volume down</p>
    <p className={className}>F</p>
    <p>Toggle fullscreen mode</p>
    <p className={className}>H</p>
    <p>Show help</p>
    <p className={spaceClassName}>Space</p>
    <p>Pause/Play video</p>
    <div className="grid grid-cols-[repeat(10,auto)] grid-rows-[repeat(3,auto)] col-span-2 gap-x-2 gap-y-1 items-center content-center text-center">
      <p className="col-span-10">Set playback speed</p>
      <p className={className}>1</p>
      <p className={className}>2</p>
      <p className={className}>3</p>
      <p className={className}>4</p>
      <p className={className}>5</p>
      <p className={className}>6</p>
      <p className={className}>7</p>
      <p className={className}>8</p>
      <p className={className}>9</p>
      <p className={className}>0</p>
      <p className="font-normal text-base">10%</p>
      <p className="font-normal text-base">20%</p>
      <p className="font-normal text-base">30%</p>
      <p className="font-normal text-base">40%</p>
      <p className="font-normal text-base">50%</p>
      <p className="font-normal text-base">60%</p>
      <p className="font-normal text-base">70%</p>
      <p className="font-normal text-base">80%</p>
      <p className="font-normal text-base">90%</p>
      <p className="font-normal text-base">100%</p>
    </div>
  </div>
}

function App() {

  const [videoUrl, setVideoUrl] = useState<string>();
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [volume, setVolume] = useState(1);
  const [autoIncrement, setAutoIncrement] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [isAdvancedUpload, setIsAdvancedUpload] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setIsAdvancedUpload(function () {
      const div = document.createElement('div');
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
    }());
  }, [])

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      // console.log(e.key)
      const vid = videoRef.current;
      switch (e.key) {
        case "ArrowLeft":
          if (vid)
            vid.currentTime = Math.max(0, vid.currentTime - 3);
          break;
        case "ArrowRight":
          if (vid)
            vid.currentTime = Math.min(vid.duration, vid.currentTime + 3);
          break;
        case "f":
          if (vid && document.fullscreenElement)
            document.exitFullscreen()
          else if (vid)
            vid.requestFullscreen()
          break;
        case "h":
          setShowHelp(prev => !prev)
          break;
        case "ArrowUp":
          setVolume(prev => Math.min(1, prev + 0.05));
          break;
        case "ArrowDown":
          setVolume(prev => Math.max(0, prev - 0.05));
          break;
        case "[":
          setPlaybackSpeed(prev => Math.max(0.1, prev - 0.01));
          break;
        case "]":
          setPlaybackSpeed(prev => Math.min(2, prev + 0.01));
          break;
        case "1":
          setPlaybackSpeed(0.1);
          break;
        case "2":
          setPlaybackSpeed(0.2);
          break;
        case "3":
          setPlaybackSpeed(0.3);
          break;
        case "4":
          setPlaybackSpeed(0.4);
          break;
        case "5":
          setPlaybackSpeed(0.5);
          break;
        case "6":
          setPlaybackSpeed(0.6);
          break;
        case "7":
          setPlaybackSpeed(0.7);
          break;
        case "8":
          setPlaybackSpeed(0.8);
          break;
        case "9":
          setPlaybackSpeed(0.9);
          break;
        case "0":
          setPlaybackSpeed(1);
          break;
        case " ":
          if (vid)
            vid.paused ? vid.play() : vid.pause();
      }
    }
    window.addEventListener('keydown', handler)
    return () => window.removeEventListener('keydown', handler);
  }, [videoRef])

  useEffect(() => {
    if (videoRef.current)
      videoRef.current.playbackRate = playbackSpeed;
  }, [playbackSpeed, videoUrl, videoRef])

  useEffect(() => {
    if (videoRef.current)
      videoRef.current.volume = volume;
  }, [volume, videoUrl, videoRef])

  const fileHandler = async (files: FileList | null) => {
    if (files && files.length) {
      const file = files[0];
      const blob = new Blob([await file.arrayBuffer()]);
      const url = URL.createObjectURL(blob)
      setVideoUrl(prev => {
        if (prev)
          URL.revokeObjectURL(prev);
        return url
      });
    }
  }

  return (
    <div className="App text-white h-screen max-h-screen w-screen grid grid-cols-1 grid-rows-[auto,1fr,auto,auto] place-items-center"
      onDragOver={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true) }}
      onDragEnter={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(true) }}
      onDragLeave={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false) }}
      onDragEnd={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false) }}
      onDragExit={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false) }}
      onDrop={(e) => { e.preventDefault(); e.stopPropagation(); setIsDragging(false); fileHandler(e.dataTransfer.files) }}>
      <label htmlFor="fileinput"
        className={`px-4 py-2 border-4 border-dashed my-2 border-white rounded-lg cursor-pointer ${isDragging ? 'font-bold text-lg' : ''}`}
      >
        Select Video{isAdvancedUpload && " or Drop Video Here"}
      </label>
      <input className="hidden" id="fileinput" type="file" onChange={(e) => fileHandler(e.target.files)} />
      <div className="h-full w-full flex justify-center items-center flex-grow min-h-0">
        {videoUrl ?
          <video className="h-full" ref={videoRef} src={videoUrl} autoPlay
            onClick={(e) => e.currentTarget.paused ? e.currentTarget.play() : e.currentTarget.pause()}
            onEnded={(e) => {
              if (autoIncrement)
                setPlaybackSpeed(prev => Math.min(2, prev + 0.01));
              e.currentTarget.play()
            }} />
          :
          <Help borderColor={"border-white"} />
        }
      </div>
      <div className="flex items-center gap-2">
        <p>Speed: {(playbackSpeed * 100).toFixed(0)}%</p>
        |
        <p>Volume: {(volume * 100).toFixed(0)}%</p>
        |
        <div className="flex items-center">
          <input className="mr-1" id="autoinc" type="checkbox" onChange={(e) => setAutoIncrement(e.target.checked)} />
          <label htmlFor="autoinc">Auto increment speed &#40;each loop&#41;</label>
        </div>
        {videoUrl && <>
          |
          <button className="underline text-cyan-500 text-lg" onClick={() => setShowHelp(true)}>Help <FontAwesomeIcon icon={faQuestionCircle} /></button>
        </>
        }
      </div>
      <p>Made with <FontAwesomeIcon className="text-red-600" icon={faHeart} /> by Bets | &copy; 2024 - All Rights Reserved</p>
      {showHelp && <div className="fixed left-0 right-0 top-0 bottom-0 bg-black/40 flex justify-center items-center cursor-pointer" onClick={() => setShowHelp(false)}>
        <div className="p-4 rounded-lg bg-white text-black">
          <Help />
        </div>
      </div>}
    </div>
  );
}

export default App;
